import { Dialog } from '@headlessui/react';

const ModalHeader = ({ title }) => {
  return (
    <div className="akoconf-relative">
      <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/images/head.jpg`} alt="" className="object-cover" />
      <Dialog.Title className="akoconf-absolute akoconf-text-white akoconf-text-center akoconf-w-full akoconf-top-1/2 akoconf--translate-y-1/2 akoconf-text-3xl md:akoconf-text-4xl akoconf-font-bold">{title}</Dialog.Title>
    </div>
  )
}

export default ModalHeader;
