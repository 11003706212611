import { Field } from 'formik';

const FormField = ({ className, ...props }) => {
  className += ' akoconf-p-2 akoconf-pl-0 !akoconf-border-t-0 !akoconf-border-x-0 akoconf-border-ako-red !akoconf-outline-none focus:akoconf-ring-transparent focus:akoconf-ring-0 !akoconf-text-base !akoconf-text-black';

  return (
    <Field className={className} {...props} />
  )
}

export default FormField;
