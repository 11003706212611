const scrollToTop = () => {
  const element = document.getElementById('dialogTop');
  element.scrollIntoView();
}

const objectMap = (obj, fn) =>
  Object.fromEntries(
    Object.entries(obj).map(
      ([k, v], i) => [k, fn(v, k, i)]
    )
  );

export { scrollToTop, objectMap };
