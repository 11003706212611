import Asterisk from './Asterisk';

const Label = ({ label, isRequired = true }) => (
  <span className="akoconf-block akoconf-text-ako-gray akoconf-font-bold">
    {isRequired && <Asterisk />}
    {label}
  </span>
);

export default Label;
