import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import ModalHeader from './ModalHeader';
import ModalDescription from './ModalDescription';
import Button from './Button';
import Label from './Label';
import Switch from './Switch';
import Asterisk from './Asterisk';
import ErrorMessage from './ErrorMessage';
import FormCol, { FormLastCol } from './FormCol';
import FormField from './FormField';
import { scrollToTop } from '../lib/utils';
import Error from './Error';

const ContactForm = ({ form, data, setShowResult, setContactId }) => {
  const [terms, setTerms] = useState(null);
  const [subscribe, setSubscribe] = useState(null);
  const [error, setError] = useState(null);
  const { t, i18n: { language: locale } } = useTranslation();

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required(t('required field')),
    company: Yup.string().required(t('required field')),
    phone: Yup.string().required(t('required field')),
    email: Yup.string().email().required(t('required field')),
    zone: Yup.string().required(t('required field')),
    terms: Yup.bool().isTrue(t('required field')),
  });

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <ModalHeader title={t('step2.title')} />
      <ModalDescription description={t('step2.description')} />
      {error && <Error />}
      <Formik
       initialValues={{
        name: '',
        company: '',
        phone: '',
        email: '',
        zone: '',
        terms: false,
        subscribe: false,
       }}
       validationSchema={ValidationSchema}
       onSubmit={async (values, { setSubmitting }) => {
        await fetch(`${process.env.REACT_APP_API_URL}/${form}/contact`, {
            method: 'POST',
            body: JSON.stringify({
              ...values,
              locale,
              data,
            }),
          })
          .then((res) => res.text())
          .then((contactId) => {
            setShowResult(true);
            setContactId(contactId);
          })
          .catch(() => setError(true))
          .finally(() => setSubmitting(false))
       }}
     >
       {({ isSubmitting, setFieldValue }) => (
         <Form>
          <div className="akoconf-grid sm:akoconf-grid-cols-2 akoconf-gap-4 akoconf-mb-4 akoconf-px-4 md:akoconf-px-0">
            <FormCol>
              <label className="akoconf-block">
                <Label label={t('Name')} />
                <FormField name="name" className="akoconf-form-input akoconf-w-full" />
              </label>
              <ErrorMessage name="name" />
            </FormCol>
            <FormCol justifyEnd={false}>
              <label className="akoconf-block">
                <Label label={t('Company')} />
                <FormField name="company" className="akoconf-form-input akoconf-w-full" />
              </label>
              <ErrorMessage name="company" />
            </FormCol>
            <FormCol>
              <label className="akoconf-block">
                <Label label={t('Phone')} />
                <FormField name="phone" className="akoconf-form-input akoconf-w-full" />
              </label>
              <ErrorMessage name="phone" />
            </FormCol>
            <FormCol justifyEnd={false}>
              <label className="akoconf-block">
                <Label label={t('Email')} />
                <FormField name="email" className="akoconf-form-input akoconf-w-full" />
              </label>
              <ErrorMessage name="email" />
            </FormCol>
          </div>
          <FormLastCol>
            <label className="akoconf-block">
              <Label label={t('Geographical area')} />
              <FormField name="zone" as="select" className="akoconf-form-select akoconf-w-full">
                <option value="">{t('Select')}</option>
                <option value="spain">{t('Spain')}</option>
                <option value="europe">{t('Europe')}</option>
                <option value="usa">{t('USA')}</option>
                <option value="middle east">{t('Middle East')}</option>
              </FormField>
            </label>
            <ErrorMessage name="zone" />
          </FormLastCol>
          <div className="akoconf-mt-6 akoconf-flex akoconf-justify-center akoconf-px-4 md:akoconf-px-0">
            <div className="md:akoconf-w-1/2">
              <div className="akoconf-mb-3">
                <label className="akoconf-flex akoconf-items-center">
                  <Switch checked={terms} onChange={() => {
                    const checked = !terms;
                    setTerms(checked);
                    setFieldValue('terms', checked ? true : false);
                  }} />
                  <span className="akoconf-ml-2">
                    <Asterisk />
                    <span className="akoconf-text-xs akoconf-text-ako-gray akoconf-font-bold" dangerouslySetInnerHTML={{
                      __html: t('step2.terms')
                    }} />
                  </span>
                </label>
                <ErrorMessage name="terms" />
              </div>
              <label className="akoconf-flex akoconf-items-center">
                <Switch checked={subscribe} onChange={() => {
                  const checked = !subscribe;
                  setSubscribe(!subscribe);
                  setFieldValue('subscribe', checked ? true : false);
                }} />
                <span className="akoconf-ml-2 akoconf-text-xs akoconf-text-ako-gray akoconf-font-bold">{t('step2.subscribe')}</span>
              </label>
            </div>
          </div>
          <p className="akoconf-text-center akoconf-mt-6 akoconf-px-4 md:akoconf-px-0">
            <span className="akoconf-block akoconf-italic akoconf-text-xs akoconf-text-ako-gray akoconf-font-bold">{t('step2.info')}</span>
          </p>
          <div className="akoconf-text-center akoconf-my-8">
            <Button type="submit" disabled={isSubmitting}>{t('Finalize')}</Button>
          </div>
         </Form>
       )}
     </Formik>
    </>
  )
}

export default ContactForm;
