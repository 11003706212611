import { Switch as BaseSwitch } from '@headlessui/react'

const Switch = ({ checked, onChange }) => (
  <BaseSwitch
    checked={checked}
    onChange={onChange}
    className={`${checked ? 'akoconf-bg-teal-500' : 'akoconf-bg-ako-gray'}
    akoconf-relative akoconf-inline-flex akoconf-h-[19px] akoconf-w-[39px] akoconf-shrink-0 akoconf-cursor-pointer akoconf-rounded-full akoconf-border-2 akoconf-border-transparent akoconf-transition-colors akoconf-duration-200 akoconf-ease-in-out akoconf-focus:outline-none akoconf-focus-visible:ring-2 akoconf-focus-visible:ring-white akoconf-focus-visible:ring-opacity-75`}
  >
    <span
      aria-hidden="true"
      className={`${checked ? 'akoconf-translate-x-5' : 'akoconf-translate-x-0'}
      akoconf-pointer-events-none akoconf-inline-block akoconf-h-[15px] akoconf-w-[15px] akoconf-transform akoconf-rounded-full akoconf-bg-white akoconf-shadow-lg akoconf-ring-0 akoconf-ransition akoconf-duration-200 akoconf-ease-in-out`}
    />
  </BaseSwitch>
)

export default Switch;
