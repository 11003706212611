import { useTranslation } from 'react-i18next';

const Error = () => {
  const { t } = useTranslation();

  return (
    <div className="akoconf-text-center">
      <span className="akoconf-bg-red-300 akoconf-inline-block akoconf-mx-auto akoconf-mb-8 akoconf-p-3 akoconf-border akoconf-border-red-600 akoconf-text-red-800">
        {t('Something went wrong. Please refresh the page, we are very sorry')} :(
        </span>
    </div>
  )
}

export default Error;
