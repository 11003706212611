import { useTranslation } from 'react-i18next';

const Help = () => {
  const { t } = useTranslation();

  return (
    <div className="akoconf-flex akoconf-flex-col sm:akoconf-flex-row akoconf-justify-center akoconf-items-center akoconf-mb-4">
      <span className="akoconf-text-xs">{t('need.help')}</span>
      <div className="akoconf-inline-block sm:akoconf-ml-3 akoconf-my-2 sm:akoconf-my-0">
        <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/images/phone.webp`} className="akoconf-inline" alt="phone" width={15} />
        <a href="phone:+34938115800" className="akoconf-text-xs akoconf-ml-1.5">+34 938 115 800</a>
      </div>
      <div className="akoconf-inline-block sm:akoconf-ml-3">
        <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/images/envelop.webp`} className="akoconf-inline" alt="envelop" width={20} />
        <a href="mailto:ako@ako.com" className="akoconf-text-xs akoconf-ml-1.5">ako@ako.com</a>
      </div>
    </div>
  )
}

export default Help;
