import { useTranslation } from 'react-i18next';

const InfoResults = () => {
  const { t } = useTranslation();

  return (
    <div className="akoconf-text-xs akoconf-mt-3 akoconf-px-1">
      {t('info.results')}
    </div>
  )
}

export default InfoResults;
