import { useTranslation } from 'react-i18next';

const Thanks = () => {
  const { t } = useTranslation();

  return (
    <div className="akoconf-relative">
      <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/images/head2.jpg`} alt="" className="object-cover" />
      <div className="akoconf-absolute akoconf-top-1/2 akoconf--translate-y-1/2 akoconf-w-full">
        <div className="sm:akoconf-w-[455px] md:akoconf-w-[545px] akoconf-mx-auto akoconf-text-center sm:akoconf-text-left">
          <h2 className="akoconf-text-white akoconf-text-3xl md:akoconf-text-4xl akoconf-font-bold">{t('step4.title')}</h2>
          <p className="akoconf-text-white akoconf-text-xl md:akoconf-text-2xl sm:akoconf-text-right">{t('step4.description')}</p>
        </div>
      </div>
    </div>
  )
}

export default Thanks;
