const FormCol = ({ justifyEnd = true, children }) => (
  <div className={`sm:akoconf-flex md:akoconf-items-end ${justifyEnd ? ' md:akoconf-justify-end' : ''}`}>
    <div className="md:akoconf-w-2/3 md:akoconf-mr-3 akoconf-w-full">
      {children}
    </div>
  </div>
);

const FormLastCol = ({ children }) => (
  <div className="sm:akoconf-flex sm:akoconf-justify-center akoconf-px-4 md:akoconf-px-0">
    <div className="md:akoconf-w-1/2 md:akoconf-mr-1.5 md:akoconf-flex md:akoconf-justify-center">
      <div className="md:akoconf-w-2/3">
        {children}
      </div>
    </div>
  </div>
);

export default FormCol;

export { FormLastCol };
