import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import ContactForm from './components/ContactForm';
import { objectMap } from './lib/utils';
import Result from './components/Result';
import ModalHeader from './components/ModalHeader';
import ModalDescription from './components/ModalDescription';
import Button from './components/Button';
import Label from './components/Label';
import FormCol, { FormLastCol } from './components/FormCol';
import FormField from './components/FormField';
import ErrorMessage from './components/ErrorMessage';
import Error from './components/Error';
import Help from './components/Help';
import InfoResults from './components/InfoResults';

const AkoCoreAdvanceForm = () => {
  const [formData, setFormData] = useState(null);
  const [showContactForm, setShowContactForm] = useState(false);
  const [result, setResult] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [contactId, setContactId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t, i18n: { language: locale }} = useTranslation();

  const ValidationSchema = Yup.object().shape({
    cameraType: Yup.number().integer().positive().required(t('required field')),
    defrostType: Yup.number().integer().positive().required(t('required field')),
    productType: Yup.number().integer().positive().required(t('required field')),
  });

  useEffect(() => {
    const fetchFormData = async () => {
      await fetch(`${process.env.REACT_APP_API_URL}/${locale}/akocoreadvance/form`)
        .then((res) => res.json())
        .then((data) => {
          setFormData(data);
          setLoading(false);
        })
        .catch(() => setError(true));
    }

    fetchFormData();
  }, [locale]);

  const backHandler = () => {
    setShowContactForm(false);
    setShowResult(false);
    setContactId(null);
  }

  if (showResult) {
    return (
      <Result form="akocoreadvance" contactId={contactId} onClickBack={backHandler}>
        <div className="akoconf-bg-white akoconf-mx-auto akoconf-rounded akoconf-shadow-table akoconf-max-w-[545px] akoconf-px-5 md:akoconf-px-10 akoconf-py-5">
          <h3 className="akoconf-uppercase akoconf-font-bold akoconf-pb-4 akoconf-text-xs" dangerouslySetInnerHTML={{
            __html: t('step3.form1.title', {
              size: '<span class="!akoconf-lowercase">25m3</span>'
            })
          }} />
          <table className="akoconf-w-full md:akoconf-table-fixed akoconf-text-sm akoconf-font-bold">
            <tbody>
              <tr>
                <td className="akoconf-bg-ako-gray-medium akoconf-px-2 akoconf-py-3 akoconf-border-r-8 akoconf-border-white">
                  {t('step3.form1.td1')}
                </td>
                <td className="akoconf-text-center md:akoconf-w-44 akoconf-bg-ako-gray-medium akoconf-py-3 akoconf-px-2 md:akoconf-px-0">
                  {result.consumptionSaving}%
                </td>
              </tr>
              <tr>
                <td className="akoconf-px-2 akoconf-py-3 akoconf-border-r-8 akoconf-border-white">
                  {t('step3.form1.td2')}
                </td>
                <td className="akoconf-text-center md:akoconf-w-44 akoconf-py-3 akoconf-px-2 md:akoconf-px-0">
                  {result.defrostSaving}%
                </td>
              </tr>
              <tr>
                <td className="akoconf-bg-ako-gray-medium akoconf-px-2 akoconf-py-3 akoconf-border-r-8 akoconf-border-white">
                  {t('step3.form1.td3')}
                </td>
                <td className="akoconf-text-center md:akoconf-w-44 akoconf-bg-ako-gray-medium akoconf-py-3 akoconf-px-2 md:akoconf-px-0">
                  {result.timeCompressorSaving}%
                </td>
              </tr>
              <tr className="akoconf-text-ako-green">
                <td className="akoconf-px-2 akoconf-py-3 akoconf-border-r-8 akoconf-border-white">
                  {t('step3.form1.td4')}
                </td>
                <td className="akoconf-text-center md:akoconf-w-44 akoconf-py-3 akoconf-px-2 md:akoconf-px-0">
                  {result.payback}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="akoconf-max-w-[545px] akoconf-mx-auto">
          <InfoResults />
        </div>
      </Result>
    )
  }

  if (showContactForm) {
    const data = objectMap(result, (v, k) => {
      if (['cameraType', 'defrostType', 'productType'].includes(k)) {
        return Number(v.id);
      }
      return v;
    });

    return (
      <ContactForm form="akocoreadvance" data={data} setShowResult={setShowResult} setContactId={setContactId} />
    )
  }

  return (
    <>
      <ModalHeader title={t('step1.title')} />
      <ModalDescription description={t('step1.description')} />
      {error && <Error />}
      <Formik
       initialValues={{
        cameraType: '',
        defrostType: '',
        productType: '',
       }}
       validationSchema={ValidationSchema}
       onSubmit={async (values, { setSubmitting }) => {
        values = objectMap(values, (v) => Number(v));

        await fetch(`${process.env.REACT_APP_API_URL}/${locale}/akocoreadvance/calculate`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(values),
          })
          .then((res) => res.json())
          .then((data) => {
            setResult(data);
            setShowContactForm(true);
          })
          .catch((e) => {
            console.debug(e);
            setError(true);
          })
          .finally(() => setSubmitting(false));
       }}
     >
       {({ isSubmitting }) => (
         <Form>
          <div className="akoconf-grid sm:akoconf-grid-cols-2 akoconf-gap-4 akoconf-mb-4 akoconf-px-4 md:akoconf-px-0">
            <FormCol>
              <label className="akoconf-block">
                <Label label={t('Camara type')} />
                <FormField name="cameraType" as="select" className="akoconf-form-select akoconf-w-full" disabled={loading}>
                  <option value="">{t('Select')}</option>
                  {!loading && formData.cameraTypes.map((o) => <option key={`cameraType-${o.id}`} value={o.id}>{o.name}</option>)}
                </FormField>
              </label>
              <ErrorMessage name="cameraType" />
            </FormCol>
            <FormCol justifyEnd={false}>
              <label className="akoconf-block">
                <Label label={t('Defrost type')} />
                <FormField name="defrostType" as="select" className="akoconf-form-select akoconf-w-full" disabled={loading}>
                  <option value="">{t('Select')}</option>
                  {!loading && formData.defrostTypes.map((o) => <option key={`defrostType-${o.id}`} value={o.id}>{o.name}</option>)}
                </FormField>
              </label>
              <ErrorMessage name="defrostType" />
            </FormCol>
          </div>
          <FormLastCol>
            <label className="akoconf-block">
              <Label label={t('Product type')} />
              <FormField name="productType" as="select" className="akoconf-form-select akoconf-w-full" disabled={loading}>
                <option value="">{t('Select')}</option>
                {!loading && formData.productTypes.map((o) => <option key={`productType-${o.id}`} value={o.id}>{o.name}</option>)}
              </FormField>
            </label>
            <ErrorMessage name="productType" />
          </FormLastCol>
          <div className="akoconf-text-center akoconf-my-8">
            <Button type="submit" disabled={loading || isSubmitting}>{t('Next')}</Button>
          </div>
         </Form>
       )}
     </Formik>
     <Help />
    </>
  )
}

export default AkoCoreAdvanceForm;
