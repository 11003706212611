import { useEffect, useState } from 'react';
import AkoCoreAdvanceForm from './AkoCoreAdvanceForm';
import AkoGasNdirForm from './AkoGasNdirForm';
import WebAppForm from './WebAppForm';
import { Dialog } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import Cookies from 'js-cookie';

const forms = [
  { key: 'akocoreadvance', component: <AkoCoreAdvanceForm />},
  { key: 'akogasndir', component: <AkoGasNdirForm />},
  { key: 'webapp', component: <WebAppForm />},
]

function App() {
  const [showModal, setShowModal] = useState(false);
  const [form, setForm] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();

  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('a[href*="akoConfBtn"]').forEach((btn) => btn.addEventListener('click', (e) => {
      e.preventDefault();

      const href = btn.getAttribute('href');
      let formType = null;

      if (/akocoreadvance/.test(href)) {
        formType = 'akocoreadvance';
      } else if (/akogasndir/.test(href)) {
        formType = 'akogasndir';
      } else if (/webapp/.test(href)) {
        formType = 'webapp';
      }

      if (formType) {
        setForm(forms.find((f) => f.key === formType).component);
        setIsOpen(true);
      } else {
        setIsOpen(false);
        setForm(null);
      }
    }));

    document.querySelectorAll('.akoConfBtn').forEach((btn) => btn.addEventListener('click', (e) => {
      e.preventDefault();
      let formType = btn.getAttribute('form');

      if (!formType) {
        if (e.currentTarget.classList.contains('akocoreadvance')) {
          formType = 'akocoreadvance';
        } else if (e.currentTarget.classList.contains('akogasndir')) {
          formType = 'akogasndir';
        } else if (e.currentTarget.classList.contains('webapp')) {
          formType = 'webapp';
        }
      }

      if (formType) {
        setForm(forms.find((f) => f.key === formType).component);
        setIsOpen(true);
      } else {
        setIsOpen(false);
        setForm(null);
      }
    }));
  });

  // disable mouse wheel on input number to avoid changes
  // @see https://stackoverflow.com/questions/9712295/disable-scrolling-on-input-type-number
  document.addEventListener('wheel', () => {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur();
    }
  });

  useEffect(() => {
    const qsParsed = queryString.parse(window.location.search);
    let lang = 'en';

    if (qsParsed?.lang) {
      i18n.changeLanguage(qsParsed.lang);
    } else if (/^\/(en|es|fr)\//.test(window.location.pathname))  {
      i18n.changeLanguage(window.location.pathname.substring(1, 3));
    } else {
      const host = window.location.host;
      const href = window.location.href;

      if (host === 'www.ako.com') {
        if (/^https:\/\/www\.ako\.com\/es\//.test(href))  {
          lang = 'es';
        } else if (/^https:\/\/www\.ako\.com\/fr\//.test(href))  {
          lang = 'fr';
        }
      } else {
        lang = Cookies.get('wp-wpml_current_language');
        if (!lang) {
          lang = Cookies.get('_icl_visitor_lang_js');
        }
      }

      i18n.changeLanguage(lang); // en is not the default
    }

    setShowModal(true);
  }, [i18n]);

  return (
    <>
      {process.env.NODE_ENV !== 'production' && (
        <>
          <button key="all" className="akoConfBtn">All</button>
          {forms.map((f) => (
            // <button key={f.key} className="akoConfBtn akoconf-ml-2" form={f.key}>{f.key}</button>
            <a key={f.key} href={`akoConfBtn ${f.key}`} className={`akoConfBtn akoconf-ml-2 ${f.key}`}>{f.key}</a>
          ))}
          {/* <a href="https://google.com" className="akoconf-ml-4 akoConfBtn" form="akocoreadvance">Link test</a>

          <a className="n2-style-efd183a09c4fc12ab104905af5dfcf0d-heading  n2-ow" href="ddddd akoConfBtn webapp" tabIndex="0"><div><div>CALCULAR AHORA</div></div></a> */}
        </>
      )}
      {showModal && (
        <Dialog open={isOpen} className="akoconf-relative akoconf-z-[2000]" onClose={() => setIsOpen(false)}>
          <div className="akoconf-fixed akoconf-inset-0 akoconf-bg-overlay akoconf-opacity-80" aria-hidden="true" />
          <div className="akoconf-fixed akoconf-inset-0 akoconf-m-4 akoconf-overflow-y-auto">
            <div id="dialogTop" />
            <div id="ako-configurator" className="akoconf-flex akoconf-items-center akoconf-justify-center">
              <Dialog.Panel className="akoconf-w-full akoconf-max-w-3xl akoconf-bg-white">
                {form ? form : 'Select a form'}
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
}

export default App;
