import { useTranslation } from 'react-i18next';
import FormField from './FormField';

const YesNoField = ({ name }) => {
  const { t } = useTranslation();

  return (
    <FormField name={name} as="select" className="akoconf-form-select akoconf-w-full">
      <option value="">{t('Select')}</option>
      <option value="1">{t('Yes')}</option>
      <option value="0">{t('No')}</option>
    </FormField>
  )
}

export default YesNoField;
