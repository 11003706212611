const Button = ({ type, className = '', children, ...props }) => {
  className += ' akoconf-bg-ako-red akoconf-text-white akoconf-rounded-lg akoconf-py-3 akoconf-px-10 akoconf-shadow-button disabled:akoconf-cursor-not-allowed disabled:akoconf-opacity-80';

  return (
    <button
      type={type}
      className={className.trim()}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button;
