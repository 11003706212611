import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import YesNoField from './components/YesNoField';
import ContactForm from './components/ContactForm';
import Result from './components/Result';
import ModalHeader from './components/ModalHeader';
import ModalDescription from './components/ModalDescription';
import FormCol, { FormLastCol } from './components/FormCol';
import ErrorMessage from './components/ErrorMessage';
import FormField from './components/FormField';
import Label from './components/Label';
import Button from './components/Button';
import Error from './components/Error';
import Help from './components/Help';

const WebAppForm = () => {
  const [formData, setFormData] = useState(null);
  const [showContactForm, setShowContactForm] = useState(false);
  const [result, setResult] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [contactId, setContactId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t, i18n: { language: locale }} = useTranslation();

  const ValidationSchema = Yup.object().shape({
    installationType: Yup.string().required(t('required field')),
    refrigerantGas: Yup.string().required(t('required field')),
    compressorRoom: Yup.string(),
    installationZone: Yup.string().required(t('required field')),
    installationVoltage: Yup.string().required(t('required field')),
    numPositiveChambers: Yup.number().integer().min(0).required(t('required field')),
    valveTypePositiveChambers: Yup.string().required(t('required field')),
    numNegativeChambers: Yup.number().integer().min(0).required(t('required field')),
    valveTypeNegativeChambers: Yup.string().required(t('required field')),
    numWorkshops: Yup.number().integer().min(0).required(),
    numPositiveFurniture: Yup.number().integer().min(0).required(t('required field')),
    numNegativeFurniture: Yup.number().integer().min(0).required(t('required field')),
    telemanagementAndMonitoringSystem: Yup.string(),
  });

  useEffect(() => {
    const fetchFormData = async () => {
      await fetch(`${process.env.REACT_APP_API_URL}/${locale}/webapp/form`)
        .then((res) => res.json())
        .then((data) => {
          setFormData(data);
          setLoading(false);
        })
        .catch(() => setError(true));
    }

    fetchFormData();
  }, [locale]);

  const backHandler = () => {
    setShowContactForm(false);
    setShowResult(false);
    setContactId(null);
  }

  const ListMaterials = ({ showMonitoring, materials }) => {
    const columns = {
      compressorRoom: ['Gas detection', 'Control', 'Remote management and monitoring system'],
      positiveCameras: ['Control', 'Gas detection'],
      negativeCameras: ['Control', 'Record subjected to metrological control', 'Locked man alarm with battery', 'Gas detection and h.e. without battery'],
      workers: ['Gas detection'],
      positiveFurniture: ['Monitoring', 'Control'],
      negativeFurniture: ['Monitoring', 'Metrologically controlled temperature measurement', 'Control'],
    }

    return (
      <>
        {Object.entries(materials).map(([key, data]) => {
          return (
            <div key={key} className="akoconf-bg-white akoconf-rounded akoconf-shadow-table akoconf-p-5 akoconf-mb-3 last:akoconf-mb-0">
              <span className="akoconf-uppercase akoconf-font-bold">{t(key)}</span>
              <div className="akoconf-flex akoconf-mt-3 sm:akoconf-gap-x-3 akoconf-flex-col akoconf-items-center sm:akoconf-items-stretch sm:akoconf-flex-row akoconf-gap-y-3 sm:akoconf-gap-y-0">
                {columns[key].map((title, i) => {
                  if (showMonitoring || !['Remote management and monitoring system', 'Monitoring'].includes(title)) {
                    const colData = data[i];

                    return (
                      <div key={`${key}-${i}`} className="akoconf-w-[195px] akoconf-flex akoconf-flex-col akoconf-shadow-table akoconf-p-3">
                        <div className="akoconf-mb-2">
                          <span className="akoconf-block akoconf-text-ako-red akoconf-uppercase akoconf-text-sm akoconf-font-bold">
                            {t(title)}
                          </span>
                          {Array.isArray(colData) && colData[1] && Number.isInteger(colData[1]) ? (
                            <span className="akoconf-text-xs">{colData[1]} {t('unit', { count: colData[1] })}</span>
                          ) : null}
                        </div>
                        <Col data={colData} />
                      </div>
                    )
                  }
                  return null;
                })}
              </div>
            </div>
          )
        })}
      </>
    )
  }

  const Col = ({ data }) => {
    const discriminator = (data) => {
      if (!data) {
        return <ColNull />
      }

      const value = data[0];

      if (Array.isArray(value) || value.indexOf('AKO') !== -1) {
        return <ColProduct product={value} />
      } else {
        return <ColText text={value} />
      }
    }

    return discriminator(data);
  }

  const ColProduct = ({ product }) => {
    let name = product;
    let imageName = product;

    if (Array.isArray(product)) {
      name = product.join(' ');
      imageName = product.join('_');
    }

    return (
      <div className="akoconf-text-center akoconf-mt-auto">
        <div className="akoconf-h-[86px]">
          <img src={`${process.env.REACT_APP_API_BASE_URL}/assets/products/${imageName.replace(/\s\(.+/, '')}.jpg`} alt={name} className="akoconf-object-contain akoconf-h-full akoconf-mx-auto" />
        </div>
        <span className="akoconf-text-xs akoconf-block akoconf-leading-4">{name}</span>
      </div>
    )
  }

  const ColText = ({ text }) => {
    return (
      <div className="akoconf-text-center akoconf-m-auto">
        <img src={`${process.env.REACT_APP_API_BASE_URL}/assets/images/info.jpg`} alt="info icon" width={23} className="akoconf-mx-auto" />
        <span className="akoconf-text-sm akoconf-text-ako-gray akoconf-block akoconf-leading-5 akoconf-mt-2">
          {text}
        </span>
      </div>
    )
  }

  const ColNull = () => {
    return (
      <div className="akoconf-text-center akoconf-m-auto">
        <img src={`${process.env.REACT_APP_API_BASE_URL}/assets/images/arrow.jpg`} alt="arrow icon" width={23} className="akoconf-mx-auto" />
        <span className="akoconf-text-sm akoconf-text-ako-gray akoconf-block akoconf-leading-5 akoconf-mt-2">
          {t('Does not apply')}
        </span>
      </div>
    )
  }

  if (showResult) {
    return (
      <Result form="webapp" contactId={contactId} onClickBack={backHandler}>
        <div className="akoconf-overflow-x-scroll">
          {/* <div className="akoconf-min-w-[660px]"> */}
            <ListMaterials showMonitoring={Boolean(result.telemanagementAndMonitoringSystem)} materials={result.listMaterialsToInstall} />
          {/* </div> */}
        </div>
      </Result>
    )
  }

  if (showContactForm) {
    return (
      <ContactForm form="webapp" data={result} setShowResult={setShowResult} setContactId={setContactId} />
    )
  }

  return (
    <>
      <ModalHeader title={t('step1.title')} />
      <ModalDescription description={t('step1.description')} />
      {error && <Error />}
      <Formik
       initialValues={{
        installationType: '',
        refrigerantGas: '',
        compressorRoom: '',
        installationZone: '',
        installationVoltage: '',
        numPositiveChambers: '',
        valveTypePositiveChambers: '',
        numNegativeChambers: '',
        valveTypeNegativeChambers: '',
        numWorkshops: '',
        numPositiveFurniture: '',
        numNegativeFurniture: '',
        telemanagementAndMonitoringSystem: '',
       }}
       validationSchema={ValidationSchema}
       onSubmit={async (values, { setSubmitting }) => {
        values.compressorRoom = values.compressorRoom === '1';
        values.telemanagementAndMonitoringSystem = values.telemanagementAndMonitoringSystem === '1';

        await fetch(`${process.env.REACT_APP_API_URL}/${locale}/webapp/calculate`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(values),
          })
          .then((res) => res.json())
          .then((data) => {
            setResult(data);
            setShowContactForm(true);
          })
          .catch((e) => {
            console.debug(e);
            setError(true);
          })
          .finally(() => setSubmitting(false));
       }}
     >
       {({ isSubmitting }) => (
         <Form>
          <div className="akoconf-grid sm:akoconf-grid-cols-2 akoconf-gap-4 akoconf-mb-4 akoconf-px-4 md:akoconf-px-0">
            <FormCol>
              <label className="akoconf-block">
                <Label label={t('Installation type')} />
                <FormField name="installationType" as="select" className="akoconf-form-select akoconf-w-full" disabled={loading}>
                  <option value="">{t('Select')}</option>
                  {!loading && formData.installationTypes.map((o) => {
                    const [k, v] = Object.entries(o)[0];
                    return <option key={`installationType-${k}`} value={k}>{v}</option>
                  })}
                </FormField>
              </label>
              <ErrorMessage name="installationType" />
            </FormCol>
            <FormCol justifyEnd={false}>
              <label className="akoconf-block">
                <Label label={t('Refrigerant gas')} />
                <FormField name="refrigerantGas" as="select" className="akoconf-form-select akoconf-w-full" disabled={loading}>
                  <option value="">{t('Select')}</option>
                  {!loading && formData.refrigerantGases.map((o) => <option key={`refrigerantGas-${o}`} value={o}>{o}</option>)}
                </FormField>
              </label>
              <ErrorMessage name="refrigerantGas" />
            </FormCol>
            <FormCol>
              <label className="akoconf-block">
                <Label label={t('Compressor room?')} />
                <YesNoField name="compressorRoom" />
              </label>
              <ErrorMessage name="compressorRoom" />
            </FormCol>
            <FormCol justifyEnd={false}>
              <label className="akoconf-block">
                <Label label={t('Installation zone')} />
                <FormField name="installationZone" as="select" className="akoconf-form-select akoconf-w-full" disabled={loading}>
                  <option value="">{t('Select')}</option>
                  {!loading && formData.installationZones.map((o) => {
                    const [k, v] = Object.entries(o)[0];
                    return <option key={`installationZone-${k}`} value={k}>{v}</option>
                  })}
                </FormField>
              </label>
              <ErrorMessage name="installationZone" />
            </FormCol>
            <FormCol>
              <label className="akoconf-block">
                <Label label={t('Installation voltage')} />
                <FormField name="installationVoltage" as="select" className="akoconf-form-select akoconf-w-full" disabled={loading}>
                  <option value="">{t('Select')}</option>
                  {!loading && formData.installationVoltages.map((o) => <option key={`installationVoltage-${o}`} value={o}>{o}</option>)}
                </FormField>
              </label>
              <ErrorMessage name="installationVoltage" />
            </FormCol>
            <FormCol justifyEnd={false}>
              <label className="akoconf-block">
                <Label label={t('Number of positive chambers')} />
                <FormField name="numPositiveChambers" type="number" className="akoconf-form-input akoconf-w-full" />
              </label>
              <ErrorMessage name="numPositiveChambers" />
            </FormCol>
            <FormCol>
              <label className="akoconf-block">
                <Label label={t('Valve type in positive chambers')} />
                <FormField name="valveTypePositiveChambers" as="select" className="akoconf-form-select akoconf-w-full" disabled={loading}>
                  <option value="">{t('Select')}</option>
                  {!loading && formData.valveTypesChambers.map((o) => {
                    const [k, v] = Object.entries(o)[0];
                    return <option key={`valveTypePositiveChambers-${k}`} value={k}>{v}</option>
                  })}
                </FormField>
              </label>
              <ErrorMessage name="valveTypePositiveChambers" />
            </FormCol>
            <FormCol justifyEnd={false}>
              <label className="akoconf-block">
                <Label label={t('Number of negative chambers')} />
                <FormField name="numNegativeChambers" type="number" className="akoconf-form-input akoconf-w-full" />
              </label>
              <ErrorMessage name="numNegativeChambers" />
            </FormCol>
            <FormCol>
              <label className="akoconf-block">
                <Label label={t('Valve type in negative chambers')} />
                <FormField name="valveTypeNegativeChambers" as="select" className="akoconf-form-select akoconf-w-full" disabled={loading}>
                  <option value="">{t('Select')}</option>
                  {!loading && formData.valveTypesChambers.map((o) => {
                    const [k, v] = Object.entries(o)[0];
                    return <option key={`valveTypeNegativeChambers-${k}`} value={k}>{v}</option>
                  })}
                </FormField>
              </label>
              <ErrorMessage name="valveTypeNegativeChambers" />
            </FormCol>
            <FormCol justifyEnd={false}>
              <label className="akoconf-block">
                <Label label={t('Number of workshops')} />
                <FormField name="numWorkshops" type="number" className="akoconf-form-input akoconf-w-full" />
              </label>
              <ErrorMessage name="numWorkshops" />
            </FormCol>
            <FormCol>
              <label className="akoconf-block">
                <Label label={t('Number of positive furniture')} />
                <FormField name="numPositiveFurniture" type="number" className="akoconf-form-input akoconf-w-full" />
              </label>
              <ErrorMessage name="numPositiveFurniture" />
            </FormCol>
            <FormCol justifyEnd={false}>
              <label className="akoconf-block">
                <Label label={t('Number of negative furniture')} />
                <FormField name="numNegativeFurniture" type="number" className="akoconf-form-input akoconf-w-full" />
              </label>
              <ErrorMessage name="numNegativeFurniture" />
            </FormCol>
          </div>
          <FormLastCol>
            <label className="akoconf-block">
              <Label label={t('Telemanagement and Monitoring System?')} />
              <YesNoField name="telemanagementAndMonitoringSystem" />
            </label>
            <ErrorMessage name="telemanagementAndMonitoringSystem" />
          </FormLastCol>
          <div className="akoconf-text-center akoconf-my-8">
            <Button type="submit" disabled={loading || isSubmitting}>{t('Next')}</Button>
          </div>
         </Form>
       )}
     </Formik>
     <Help />
    </>
  )
}

export default WebAppForm;
