import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { scrollToTop } from '../lib/utils';
import Button from './Button';
import Thanks from './Thanks';

const Result = ({ form, contactId, children }) => {
  const [loading, setLoading] = useState(false);
  const [showThanks, setShowThanks] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleUseful = async (useful) => {
    if (!loading) {
      setLoading(true);

      await fetch(`${process.env.REACT_APP_API_URL}/${form}/useful/${contactId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ useful }),
      })
      .then(() => setShowThanks(true))
      .catch(() => {})
      .finally(setLoading(false));
    }
  }

  if (showThanks) {
    return <Thanks />
  }

  return (
    <div className="akoconf-bg-ako-gray-clear akoconf-px-4 akoconf-py-8">
      <h2 className="akoconf-text-center akoconf-text-3xl md:akoconf-text-4xl akoconf-font-bold">{t('step3.title')}</h2>
      <p className="akoconf-text-center akoconf-mt-2">{t('step3.description')}</p>
      <div className="akoconf-my-8">
        {children}
      </div>
      <div className="akoconf-flex akoconf-flex-col-reverse akoconf-justify-center sm:akoconf-flex-row">
        <div className="akoconf-flex akoconf-justify-center">
          <Button onClick={() => handleUseful(false)} className="akoconf-flex sm:akoconf-mr-3 akoconf-bg-[#6b6b6b] !akoconf-px-3 !akoconf-py-1 akoconf-items-center" disabled={loading}>
            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/images/no.png`} alt="" width={33} />
            <span className="akoconf-ml-2 akoconf-font-bold akoconf-text-xl">{t('step3.btn.no')}</span>
          </Button>
        </div>
        <div className="akoconf-flex akoconf-justify-center">
          <Button onClick={() => handleUseful(true)} className="akoconf-flex sm:akoconf-ml-3 !akoconf-px-3 !akoconf-py-1 akoconf-items-center akoconf-mb-3 sm:akoconf-mb-0" disabled={loading}>
            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/images/yes.png`} alt="" width={33} />
            <span className="akoconf-ml-2 akoconf-font-bold akoconf-text-xl">{t('step3.btn.yes')}</span>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Result;
