import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import ContactForm from './components/ContactForm';
import { objectMap } from './lib/utils';
import Result from './components/Result';
import ModalHeader from './components/ModalHeader';
import ModalDescription from './components/ModalDescription';
import FormCol, { FormLastCol } from './components/FormCol';
import FormField from './components/FormField';
import Button from './components/Button';
import ErrorMessage from './components/ErrorMessage';
import Label from './components/Label';
import Error from './components/Error';
import Help from './components/Help';
import InfoResults from './components/InfoResults';

const AkoGasNdirForm = () => {
  const [formData, setFormData] = useState(null);
  const [showContactForm, setShowContactForm] = useState(false);
  const [result, setResult] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [contactId, setContactId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t, i18n: { language: locale }} = useTranslation();

  const ValidationSchema = Yup.object().shape({
    zone: Yup.number().integer().positive().required(t('required field')),
    installationType: Yup.number().integer().positive().required(t('required field')),
    gasType: Yup.number().integer().positive().required(t('required field')),
  });

  useEffect(() => {
    const fetchFormData = async () => {
      await fetch(`${process.env.REACT_APP_API_URL}/${locale}/akogasndir/form`)
        .then((res) => res.json())
        .then((data) => {
          setFormData(data);
          setLoading(false);
        })
        .catch(() => setError(true));
    }

    fetchFormData();
  }, [locale]);

  const backHandler = () => {
    setShowContactForm(false);
    setShowResult(false);
    setContactId(null);
  }

  if (showResult) {
    return (
      <Result form="akogasndir" contactId={contactId} onClickBack={backHandler}>
        <div className="akoconf-bg-white akoconf-mx-auto akoconf-rounded akoconf-shadow-table akoconf-p-5 akoconf-overflow-x-scroll">
          <table className="akoconf-min-w-[500px] akoconf-w-full md:akoconf-table-fixed akoconf-text-sm akoconf-font-bold">
            <thead>
              <tr>
                <th className="akoconf-border-b-8 akoconf-border-white">&nbsp;</th>
                <th className="akoconf-bg-ako-red akoconf-text-white akoconf-uppercase akoconf-p-2 akoconf-border-b-8 akoconf-border-l-8 akoconf-border-white">
                  {t('step3.form2.th', { parcentage: '15%' })}
                  </th>
                <th className="akoconf-bg-ako-red akoconf-text-white akoconf-uppercase akoconf-p-2 akoconf-border-b-8 akoconf-border-l-8 akoconf-border-white">
                  {t('step3.form2.th', { parcentage: '25%' })}
                </th>
                <th className="akoconf-bg-ako-red akoconf-text-white akoconf-uppercase akoconf-p-2 akoconf-border-b-8 akoconf-border-l-8 akoconf-border-white">
                  {t('step3.form2.th', { parcentage: '35%' })}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="akoconf-bg-ako-gray-medium akoconf-px-2 akoconf-py-3">
                  {t('step3.form2.td1')}
                </td>
                <td className="akoconf-bg-ako-gray-medium akoconf-px-2 akoconf-py-3 akoconf-text-center akoconf-border-l-8 akoconf-border-white">
                  {result.calc1LeakRate15}
                </td>
                <td className="akoconf-bg-ako-gray-medium akoconf-px-2 akoconf-py-3 akoconf-text-center akoconf-border-l-8 akoconf-border-white">
                  {result.calc1LeakRate25}
                </td>
                <td className="akoconf-bg-ako-gray-medium akoconf-px-2 akoconf-py-3 akoconf-text-center akoconf-border-l-8 akoconf-border-white">
                  {result.calc1LeakRate35}
                </td>
              </tr>
              <tr>
                <td className="akoconf-px-2 akoconf-py-3">
                  {t('step3.form2.td2')}
                </td>
                <td className="akoconf-px-2 akoconf-py-3 akoconf-text-center akoconf-border-l-8 akoconf-border-white">
                  {result.calc2LeakRate15}
                </td>
                <td className="akoconf-px-2 akoconf-py-3 akoconf-text-center akoconf-border-l-8 akoconf-border-white">
                  {result.calc2LeakRate25}
                </td>
                <td className="akoconf-px-2 akoconf-py-3 akoconf-text-center akoconf-border-l-8 akoconf-border-white">
                  {result.calc2LeakRate35}
                </td>
              </tr>
              <tr className="akoconf-text-ako-green">
                <td className="akoconf-bg-ako-gray-medium akoconf-px-2 akoconf-py-3">
                  {t('step3.form2.td3')}
                </td>
                <td className="akoconf-bg-ako-gray-medium akoconf-px-2 akoconf-py-3 akoconf-text-center akoconf-border-l-8 akoconf-border-white">
                  {result.calc3LeakRate15}
                </td>
                <td className="akoconf-bg-ako-gray-medium akoconf-px-2 akoconf-py-3 akoconf-text-center akoconf-border-l-8 akoconf-border-white">
                  {result.calc3LeakRate25}
                </td>
                <td className="akoconf-bg-ako-gray-medium akoconf-px-2 akoconf-py-3 akoconf-text-center akoconf-border-l-8 akoconf-border-white">
                  {result.calc3LeakRate35}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <InfoResults />
      </Result>
    )
  }

  if (showContactForm) {
    const data = objectMap(result, (v, k) => {
      if (['zone', 'installationType', 'gasType'].includes(k)) {
        return Number(v.id);
      }
      return v;
    });

    return (
      <ContactForm form="akogasndir" data={data} setShowResult={setShowResult} setContactId={setContactId} />
    )
  }

  return (
    <>
      <ModalHeader title={t('step1.title')} />
      <ModalDescription description={t('step1.description')} />
      {error && <Error />}
      <Formik
        initialValues={{
          zone: '',
          installationType: '',
          gasType: '',
        }}
        validationSchema={ValidationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          values = objectMap(values, (v) => Number(v));

          await fetch(`${process.env.REACT_APP_API_URL}/${locale}/akogasndir/calculate`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(values),
            })
            .then((res) => res.json())
            .then((data) => {
              setResult(data);
              setShowContactForm(true);
            })
            .catch((e) => {
              console.debug(e);
              setError(true);
            })
            .finally(() => setSubmitting(false));
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="akoconf-grid sm:akoconf-grid-cols-2 akoconf-gap-4 akoconf-mb-4 akoconf-px-4 md:akoconf-px-0">
              <FormCol>
                <label className="akoconf-block">
                  <Label label={t('Zone')} />
                  <FormField name="zone" as="select" className="akoconf-form-select akoconf-w-full" disabled={loading}>
                    <option value="">{t('Select')}</option>
                    {!loading && formData.zones.map((o) => <option key={`zone-${o.id}`} value={o.id}>{o.name}</option>)}
                  </FormField>
                </label>
                <ErrorMessage name="zone" />
              </FormCol>
              <FormCol justifyEnd={false}>
                <label className="akoconf-block">
                  <Label label={t('Installation type')} />
                  <FormField name="installationType" as="select" className="akoconf-form-select akoconf-w-full" disabled={loading}>
                    <option value="">{t('Select')}</option>
                    {!loading && formData.installationTypes.map((o) => <option key={`installationType-${o.id}`} value={o.id}>{o.name}</option>)}
                  </FormField>
                </label>
                <ErrorMessage name="installationType" />
              </FormCol>
            </div>
            <FormLastCol>
              <label className="akoconf-block">
                <Label label={t('Gas type')} />
                <FormField name="gasType" as="select" className="akoconf-form-select akoconf-w-full" disabled={loading}>
                  <option value="">{t('Select')}</option>
                  {!loading && formData.gasTypes.map((o) => <option key={`gasTypes-${o.id}`} value={o.id}>{o.name}</option>)}
                </FormField>
              </label>
              <ErrorMessage name="gasType" />
            </FormLastCol>
            <div className="akoconf-text-center akoconf-my-8">
              <Button type="submit" disabled={loading || isSubmitting}>{t('Next')}</Button>
            </div>
          </Form>
        )}
      </Formik>
      <Help />
    </>
  )
}

export default AkoGasNdirForm;
